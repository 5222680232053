// import React from 'react'

// const View = () => {
//   return (
//     <>

//     </>
//   )
// }

// export default View

import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { _fetch } from "../config/Api";
import { base_url } from "../config/config";
import { ColorRing } from "react-loader-spinner";
import dateFormat from "dateformat";
import { useLocation } from "react-router-dom";
import { Pagination } from "antd";
const View = () => {
  const [data, setData] = useState();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSer, setpageSer] = useState(0);
  console.log("data", data);

  const getQueryParams = (search) => {
    return new URLSearchParams(search);
  };

  const queryParams = getQueryParams(location.search);
  const id = queryParams.get("id");

  console.log("id", id);

  const handledashboard = async (page = 1, pageSize = 10) => {
    try {
      const skip = (page - 1) * pageSize;
      setpageSer(skip);
      const requestData = {
        // limit: pageSize,
        // skip: skip,
        id,
      };

      let response = await _fetch(
        `${base_url}api/trader/allPositions`,
        "post",
        requestData
      );

      if (response.success === true) {
        setData(response);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  };

  useEffect(() => {
    handledashboard(currentPage, 10);
  }, [currentPage]);
  const handlePaginationChange = (pageOne) => {
    setCurrentPage(pageOne);
  };
  // const records = () => {
  //     if (data && data?.data && data?.data?.length > 0) {
  //         return data?.data?.map((row, i) => {
  //             const serialNumber = pageSer + i + 1;
  //             return (
  //                 <tr className="table-row" key={i}>
  //                     <td className="tabel-text w inter">{serialNumber}</td>
  //                     <td className="tabel-text w inter">{row?.symbol}</td>
  //                     <td className="tabel-text w inter">{row?.positionAmt}</td>
  //                     <td className="tabel-text w inter">{row?.entryPrice}</td>
  //                     <td className="tabel-text w inter">{row?.breakEvenPrice}</td>
  //                     <td className="tabel-text w inter">{row?.markPrice}</td>
  //                     <td className="tabel-text w inter">{row?.unRealizedProfit}</td>
  //                     <td className="tabel-text w inter">{row?.liquidationPrice}</td>
  //                     <td className="tabel-text w inter">{row?.leverage}</td>
  //                     <td className="tabel-text w inter">{row?.maxNotionalValue}</td>
  //                     <td className="tabel-text w inter">{row?.marginType}</td>
  //                     <td className="tabel-text w inter">{row?.isolatedMargin}</td>
  //                     <td className="tabel-text w inter">{row?.isAutoAddMargin}</td>
  //                     <td className="tabel-text w inter">{row?.positionSide}</td>
  //                     <td className="tabel-text w inter">{row?.notional}</td>
  //                     <td className="tabel-text w inter">{row?.isolatedWallet}</td>
  //                     <td className="tabel-text w inter">{row?.updateTime}</td>
  //                     {/* <td className="tabel-text w inter">{row?.isolated}</td> */}
  //                     <td className="tabel-text w inter">{row?.adlQuantile}</td>

  //                     <td>{dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</td>

  //                 </tr>
  //             );
  //         });
  //     } else {
  //         return (
  //             <tr>
  //                 <td colSpan="13">Data Not Found</td>
  //             </tr>
  //         );
  //     }
  // };

  const records = () => {
    if (data && data?.data && data?.data?.length > 0) {
      return data?.data?.map((row, i) => {
        const serialNumber = pageSer + i + 1;
        return (
          <div key={i} className="container mt-5">
            <div className="card border-primary">
              <div className="card-header bg-white d-flex justify-content-between align-items-center">
                <span className="badge bg-success">{serialNumber}</span>{" "}
                {row?.symbol}
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4">
                    <dl className="row mb-0">
                      <dt className="col-sm-5">Symbol:</dt>
                      <dd className="col-sm-7">{row?.symbol}</dd>
                      <dt className="col-sm-5">PositionAmt:</dt>
                      <dd className="col-sm-7">{row?.positionAmt}</dd>
                      <dt className="col-sm-5">EntryPrice:</dt>
                      <dd className="col-sm-7">{row?.entryPrice}</dd>
                      <dt className="col-sm-5">BreakEven Price:</dt>
                      <dd className="col-sm-7">{row?.breakEvenPrice}</dd>
                      <dt className="col-sm-5">Isolated Wallet:</dt>
                      <dd className="col-sm-7">{row?.breakEvenPrice}</dd>
                      <dt className="col-sm-5">Update Time:</dt>
                      <dd className="col-sm-7">{row?.updateTime}</dd>
                      <dt className="col-sm-5">Adl Quantile:</dt>
                      <dd className="col-sm-7">{row?.adlQuantile}</dd>
                    </dl>
                  </div>
                  <div className="col-md-4">
                    <dl className="row mb-0">
                      <dt className="col-sm-5">Mark Price</dt>
                      <dd className="col-sm-7">{row?.markPrice}</dd>
                      <dt className="col-sm-5">UnRealized Profit</dt>
                      <dd className="col-sm-7 text-danger">
                        {row?.unRealizedProfit}
                      </dd>
                      <dt className="col-sm-5">Liquidation Price</dt>
                      <dd className="col-sm-7">{row?.liquidationPrice}</dd>
                      <dt className="col-sm-5">Leverage</dt>
                      <dd className="col-sm-7">{row?.leverage}</dd>

                      <dt className="col-sm-5">Margin Type:</dt>
                      <dd className="col-sm-7">{row?.marginType}</dd>
                      <dt className="col-sm-5">MaxNotionalVal:</dt>
                      <dd className="col-sm-7 text-secondary">
                        {row?.maxNotionalValue}
                      </dd>
                    </dl>
                  </div>
                  <div className="col-md-4">
                    <dl className="row mb-0">
                      <dt className="col-sm-5">Isolated Margin:</dt>
                      <dd className="col-sm-7">{row?.isolatedMargin}</dd>
                      <dt className="col-sm-5">IsAutoMargin</dt>
                      <dd className="col-sm-7 ">{row?.isAutoAddMargin}</dd>
                      <dt className="col-sm-5">positionSide</dt>
                      <dd className="col-sm-7 ">{row?.positionSide}</dd>
                      <dt className="col-sm-5">notional</dt>
                      <dd className="col-sm-7 ">{row?.notional}</dd>
                      <dt className="col-sm-5">Date:</dt>
                      <dd className="col-sm-7">
                        {dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return <div>Data Not Found</div>;
    }
  };
  return (
    <>
      <div className="section2">
        <h1 className="text-primary text-center">Positions</h1>
        <div className="table-sec">
          <div className="transactions">
            {records()}

            <div className="pagination">
              <Pagination
                style={{ cursor: "pointer" }}
                className="mx-auto"
                current={currentPage || 1}
                total={data?.count || 0}
                pageSize={10}
                onChange={handlePaginationChange}
                showSizeChanger={false}
                showQuickJumper={false}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default View;
