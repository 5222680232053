import React from "react";

const Spottrade = () => {
  return (
    <>
      <h1 className="text-center text-info">Coming Soon......</h1>
    </>
  );
};

export default Spottrade;
