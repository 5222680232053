import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { _fetch } from '../config/Api'
import { base_url } from '../config/config'
import { ColorRing } from 'react-loader-spinner';
import dateFormat from "dateformat";
import { useLocation } from 'react-router-dom';
import { Pagination } from "antd";
const Openview = () => {
    const [data, setData] = useState();
    const location = useLocation();  
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSer, setpageSer] = useState(0);
console.log('data',data);

    
    const getQueryParams = (search) => {
        return new URLSearchParams(search);
    };

    const queryParams = getQueryParams(location.search);
    const id = queryParams.get('id');  

    console.log('id', id); 

    const handledashboard = async (page = 1, pageSize = 10) => {
        try {
            const skip = (page - 1) * pageSize;
            setpageSer(skip);
            const requestData = {
                limit: pageSize,
                skip: skip,
                id: id  
            };

            let response = await _fetch(`${base_url}api/trader/tradeDetails`, 'post', requestData);

            if (response.success === true) {
                setData(response);
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.log("Error details", error);
        }
    }

    useEffect(() => {
        handledashboard(currentPage, 10);
      }, [currentPage]);
      const handlePaginationChange = (pageOne) => {
        setCurrentPage(pageOne);
       
      };
    // const records = () => {
    //     if (data && data?.data && data?.data?.length > 0) {
    //       return data?.data?.map((row, i) => {
    //         const serialNumber = pageSer + i + 1;
    //         return (
    //           <tr className="table-row" key={i}>
    //             <td className="tabel-text w inter">{serialNumber}</td>
    //             <td className="tabel-text w inter">{row?.orderId}</td>
    //             <td className="tabel-text w inter">{row?.symbol}</td>
    //             <td className="tabel-text w inter">{row?.status}</td>
    //             <td className="tabel-text w inter">{row?.price}</td>
    //             {/* <td className="tabel-text w inter">{row?.quantity}</td> */}
    //             {/* <td className="tabel-text w inter">{row?.origQty}</td> */}
    //             <td className="tabel-text w inter">{row?.type}</td>
    //             <td className="tabel-text w inter">{row?.side}</td>
    //             <td className="tabel-text w inter">{row?.quantity}</td>
    //             <td className="tabel-text w inter">{row?.batchId}</td>
    
    //             <td>{dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</td>
              
    
    
    //           </tr>
    //         );
    //       });
    //     } else {
    //       return (
    //         <tr>
    //           <td colSpan="13">Data Not Found</td>
    //         </tr>
    //       );
    //     }
    //   };
    const records = () => {
      if (data && data?.data && data?.data?.length > 0) {
        return data?.data?.map((row, i) => {
          const serialNumber = pageSer + i + 1;
          return (
            <div key={i} className="container mt-5">
              <div className="card border-primary">
                <div className="card-header bg-white d-flex justify-content-between align-items-center">
                  <span className="badge bg-success">{serialNumber}</span> {row?.symbol}
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <dl className="row mb-0">
                     
                        
                        <dt className="col-sm-5">OrderId:</dt>
                        <dd className="col-sm-7">{row?.orderId}</dd>
                        <dt className="col-sm-5">Symbol:</dt>
                        <dd className="col-sm-7">{row?.symbol}</dd>
                        <dt className="col-sm-5">status:</dt>
                        <dd className="col-sm-7">{row?.status}</dd>
                        
                       
                       
  
                      </dl>
                    </div>
                    <div className="col-md-4">
                      <dl className="row mb-0">
                        <dt className="col-sm-5">BatchId</dt>
                        <dd className="col-sm-7">{row?.batchId}</dd>
                        <dt className="col-sm-5">Quantity</dt>
                        <dd className="col-sm-7">{row?.quantity}</dd>
                        <dt className="col-sm-5">Side</dt>
                        <dd className="col-sm-7">{row?.side}</dd>
                      </dl>
                    </div>
                    <div className="col-md-4">
                      <dl className="row mb-0">
                      <dt className="col-sm-5">Type:</dt>
                      <dd className="col-sm-7">{row?.type}</dd>
                        <dt className="col-sm-5">Date:</dt>
                        <dd className="col-sm-7">{dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</dd>
                        <dt className="col-sm-5">Side</dt>
                        <dd className="col-sm-7">{row?.side}</dd>
                      </dl>
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          );
        });
      } else {
        return <div>Data Not Found</div>;
      }
    };
   
   
    return (
        <>
             <div className="section2">

<div className="table-sec">
  <div className="transactions">
  {records()}
    <div className="pagination">
              <Pagination
                style={{ cursor: "pointer" }}
                className="mx-auto"
                current={currentPage || 1}
                total={data?.count || 0}
                pageSize={10}
                onChange={handlePaginationChange}
                showSizeChanger={false}
                showQuickJumper={false}
              />

            </div>
  </div>

</div>
</div>
        </>
    )
}

export default Openview
