import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { _fetch } from "../config/Api";
import { base_url } from "../config/config";
import { ColorRing } from "react-loader-spinner";
import dateFormat from "dateformat";
import { Pagination } from "antd";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";

const Batch = () => {
  const [data, setData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSer, setpageSer] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [email, setEmail] = useState('');
  const [sponserId, SetSponserId] = useState('');
  const [phonenumber, setPhonenumber] = useState('');
  const [userId, setUserId] = useState('');
  const [filters, setFilters] = useState({
    startDate: '',
    endDate: '',
    userId: '',
    email: '',
    phonenumber: '',
    sponserId: ''
  });
  const applyFilters = () => {

    setFilters({
      startDate,
      endDate,
      userId,
      email,
      phonenumber,
      sponserId
    });
  };
  const resetFilters = () => {
    setStartDate('');
    setEndDate('');
    setUserId('');
    setEmail('');
    setPhonenumber('')
    SetSponserId('')
    setFilters({
      startDate: '',
      endDate: '',
      userId: '',
      email: '',
      phonenumber: '',
      sponserId: '',
    });
    setCurrentPage(1);
    handledashboard(1, 10, {}); // Fetch all data again
  };
  const handledashboard = async (page = 1, pageSize = 10) => {
    try {
      const skip = (page - 1) * pageSize;
      setpageSer(skip);
      const requestData = {
        limit: pageSize,
        skip: skip,
      };

      let response = await _fetch(
        `${base_url}api/trader/batchs`,
        "post",
        requestData
      );

      if (response.success === true) {
        setData(response);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  };

  useEffect(() => {
    handledashboard(currentPage, 10);
  }, [currentPage]);
  const handlePaginationChange = (pageOne) => {
    setCurrentPage(pageOne);
  };

  const records = () => {
    if (data && data?.data && data?.data?.length > 0) {
      return data?.data?.map((row, i) => {
        const serialNumber = pageSer + i + 1;
        return (
          <tr className="table-row" key={i}>
            <td className="tabel-text w inter">{serialNumber}</td>
            <td className="tabel-text w inter">{row?.symbol}</td>
            {/* <td className="tabel-text w inter">{row?.timeInForce}</td> */}

            <td className="tabel-text w inter">{row?.side}</td>
            <td className="tabel-text w inter">{row?.batchId}</td>
            <td
              className={`tabel-text w inter ${
                row?.closeStatus === true ? "text-success" : "text-danger"
              }`}
            >
              {row?.closeStatus ? "true" : "false"}
            </td>
            <td
              className={`tabel-text w inter ${
                row?.profitDistributionStatus === true
                  ? "text-success"
                  : "text-danger"
              }`}
            >
              {row?.profitDistributionStatus ? "true" : "false"}
            </td>
            {/* <td className="tabel-text w inter">{row?.exchangeName}</td> */}
            {/* <td className="tabel-text w inter">{row?.orderType}</td> */}
            <td className="tabel-text w inter">{row?.leverage}</td>
            <td className="tabel-text w inter">{row?.quantity}</td>
            {/* <td className="tabel-text w inter">{row?.limitPrice}</td> */}
            <td className="tabel-text w inter">
              {dateFormat(row.createdAt, "yyyy-mm-dd, HH:mm:ss")}
            </td>

            {/* <td>{dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</td> */}
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="13">Data Not Found</td>
        </tr>
      );
    }
  };
  // const records = () => {
  //   if (data && data?.data && data?.data?.length > 0) {
  //     return data?.data?.map((row, i) => {
  //       const serialNumber = pageSer + i + 1;
  //       return (
  //         <div key={i} className="container mt-5 p-1">
  //           <div className="card border-primary">
  //             <div className="card-header bg-white d-flex justify-content-between align-items-center">
  //               <span className="badge bg-success">{serialNumber}</span> {row?.symbol}
  //             </div>
  //             <div className="card-body p-2">
  //               <div className="row p-0">
  //                 <div className="col-md-4">
  //                   <dl className="row mb-0 positionRow">
  //                     <div className='sec-main'>
  //                       <dt className="col-6">Name:</dt>
  //                       <dd className="col-6">{row?.userId?.name}</dd>
  //                     </div>
  //                     <div className='sec-main'>
  //                       <dt className="col-6">User Id:</dt>
  //                       <dd className="col-6">{row?.userId?.userId}</dd>
  //                     </div>
  //                     <div className='sec-main'>
  //                       <dt className="col-6">TokenName:</dt>
  //                       <dd className="col-6">{row?.tokenName}</dd>
  //                     </div>

  //                   </dl>
  //                 </div>
  //                 <div className="col-md-4">
  //                   <dl className="row mb-0 positionRow">
  //                   <div className='sec-main'>
  //                       <dt className="col-6">From:</dt>
  //                       <dd className="col-6">{row?.from}</dd>
  //                     </div>
  //                     <div className='sec-main'>
  //                       <dt className="col-6">To:</dt>
  //                       <dd className="col-6">{row?.to}</dd>
  //                     </div>
  //                     <div className='sec-main'>
  //                       <dt className="col-6">value:</dt>
  //                       <dd className="col-6">{row?.value}</dd>
  //                     </div>

  //                   </dl>
  //                 </div>
  //                 <div className="col-md-4">
  //                   <dl className="row mb-0 positionRow">

  //                     <div className='sec-main'>
  //                       <dt className="col-6">Wallet Type</dt>
  //                       <dd className="col-6">{row?.walletType}</dd>
  //                     </div>
  //                     <div className='sec-main'>
  //                       <dt className="col-6">Transaction Type</dt>
  //                       <dd className="col-6">{row?.transactionType}</dd>
  //                     </div>

  //                     <div className='sec-main'>
  //                       <dt className="col-6">Date:</dt>
  //                       <dd className="col-6">{dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</dd>
  //                     </div>

  //                   </dl>
  //                 </div>

  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       );
  //     });
  //   } else {
  //     return <div>Data Not Found</div>;
  //   }
  // };

  const getExportData = async () => {
    try {
      const exportRequest = {
        limit: 1000,
        skip: 0,
        export: true,
      };

      const response = await _fetch(
        `${base_url}api/trader/batchs`,
        "post",
        exportRequest
      );

      if (!response.success) {
        throw new Error(response.message || "Failed to fetch export data");
      }

      return response.data;
    } catch (error) {
      console.error("Export data fetch error:", error);
      toast.dismiss();
      toast.error("Failed to get export data");
      throw error;
    }
  };

  const exportToPDF = async () => {
    try {
      const data = await getExportData();

      const doc = new jsPDF();

      const tableColumns = [
        "S No.",
        "Symbol",
        "Side",
        "Batch Id",
        "Close Status",
        "Profit Distribution",
        "Leverage",
        "Quantity",
        "Date",
      ];

      const tableRows = data.map((item, index) => [
        index + 1,
        item.symbol,
        item.side,
        item.batchId,
        item.closeStatus,
        item.profitDistributionStatus,
        item.leverage,
        item.quantity,
        dateFormat(item.createdAt, "yyyy-mm-dd"),
      ]);

      doc.text("Batch Report", 14, 15);

      doc.autoTable({
        head: [tableColumns],
        body: tableRows,
        startY: 20,
        theme: "grid",
        styles: { fontSize: 8 },
      });

      doc.save("batch-resport.pdf");
      toast.dismiss();
      toast.success("PDF downloaded successfully");
    } catch (error) {
      console.error("PDF export error:", error);
      toast.dismiss();
      toast.error("Failed to export PDF");
    }
  };

  const exportToExcel = async () => {
    try {
      const data = await getExportData();
      const excelData = data.map((item, index) => ({
        "S No.": index + 1,
        Symbol: item.symbol || "N/A",
        Side: item.side || "N/A",
        "Batch Id": item.batchId || "N/A",
        "Close Status": item.closeStatus ? "True" : "False",
        "Profit Distribution": item.profitDistributionStatus ? "True" : "False",
        Leverage: item.leverage || 0,
        Quantity: item.quantity || 0,
        Date: dateFormat(item.createdAt, "yyyy-mm-dd"),
      }));

      const worksheet = XLSX.utils.json_to_sheet(excelData);

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Batch History");

      XLSX.writeFile(workbook, "batch-history.xlsx");
      toast.dismiss();
      toast.success("Excel file downloaded successfully");
    } catch (error) {
      console.error("Excel export error:", error);
      toast.dismiss();
      toast.error("Failed to export Excel");
    }
  };

  return (
    <>
      <div className="container container-trade p-0">
        <h2 className="text-center mb-3 text-primary">Batch</h2>
        <div className="">
          <div className="container container-trade2 mt-2 p-1">
            <div className="text-right1 p-3">
              <button className="btn btn-primary me-2" onClick={exportToPDF}>
                Export PDF
              </button>
              <button className="btn btn-primary" onClick={exportToExcel}>
                Export Excel
              </button>
            </div>
            <div className="transactions">
              <h4 className="text-right1 p-3 text-primary">
                Total Batches: {data?.count}
              </h4>
              {/* {records()} */}
              <div className="card border-primary">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Symbol</th>
                      {/* <th scope="col">TimeInForce</th> */}
                      <th scope="col">Side</th>
                      <th scope="col">Batch Id</th>
                      <th scope="col">Close Status </th>
                      <th scope="col">Profit Distribution Status</th>
                      {/* <th scope="col">Exchange Name</th> */}
                      {/* <th scope="col">Order Type</th> */}
                      <th scope="col">Leverage </th>
                      <th scope="col">Quantity </th>
                      <th scope="col">Date </th>
                    </tr>
                  </thead>
                  <tbody>{records()}</tbody>
                </table>
              </div>
            </div>
            <div className="pagination mt-3">
              <Pagination
                style={{ cursor: "pointer" }}
                className="mx-auto"
                current={currentPage || 1}
                total={data?.count || 0}
                pageSize={10}
                onChange={handlePaginationChange}
                showSizeChanger={false}
                showQuickJumper={false}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Batch;
