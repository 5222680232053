import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Pagination } from "antd";

import { ColorRing } from "react-loader-spinner";
import { base_url } from "../config/config";
import _fetch from "../config/Api";
import dateFormat from "dateformat";

const Tradespotopen = () => {
  return (
    <>
      <h1 className="text-center text-info">Coming Soon......</h1>
    </>
  );
};

export default Tradespotopen;
