import React, { useEffect, useState } from "react";
import { base_url } from "../config/config";
import _fetch from "../config/Api";
import toast from "react-hot-toast";

const Banners = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getBannerDetails = async () => {
    try {
      let response = await _fetch(`${base_url}api/trader/getBanners`, "get");

      if (response.success === true) {
        setData(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Error fetching banners");
      console.log("Error details", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBannerDetails();
  }, []);

  const handleDelete=async(id)=>{
    try {
        const requestData={
            bannerId:id
        }
        let response = await _fetch(`${base_url}api/trader/deleteBanner`, "post", requestData);
  
        if (response.success === true) {
          toast.dismiss();
          toast.success(response.message);
          getBannerDetails();
        } else {
            toast.dismiss();
          toast.error(response.message);
        }
      } catch (error) {
        toast.error("Error fetching banners");
        console.log("Error details", error);
      } finally {
        setLoading(false);
      }
  }

  return (
    <div className="container my-5">
      <h1 className="text-primary text-center mb-4">All Banners</h1>
      {loading ? (
        <div className="text-center">Loading banners...</div>
      ) : data && data.length > 0 ? (
        <div className="row">
          {data.map((banner, index) => (
            <div className="col-md-6 col-lg-4 mb-4" key={index}>
              <div className="card h-100 shadow-sm">
                <img
                  src={banner.image}
                  alt={`Banner ${index + 1}`}
                  className="card-img-top"
                  style={{ height: "200px", objectFit: "cover" }}
                />
                <div className="card-body">
                  {/* <h5 className="card-title text-center">Banner {index + 1}</h5> */}
                  <div className="text-right1">
                  <button className="btn btn-danger" onClick={()=>{handleDelete(banner?._id)}} type="button">Delete</button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center">No banners available.</div>
      )}
    </div>
  );
};

export default Banners;
