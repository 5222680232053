// import React from 'react'
// import Sidebar from '../Sidebar/Sidebar'
// import 'bootstrap/dist/css/bootstrap.min.css';


// const PrivateLayout = ({ children }) => {

//     return (

//         <div className="d-flex flex-row w-100">
//             <Sidebar />
//             <div className='main__wrap'>
//                 {children}
//             </div>
//         </div>
//     )
// }

// export default PrivateLayout


// PrivateLayout.js
import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import "bootstrap/dist/css/bootstrap.min.css";

const PrivateLayout = ({ children }) => {
  return (
    <div className="d-flex flex-column flex-md-row w-100">
      <Sidebar />
      <div className="main__wrap flex-grow-1 p-2">
        {children}
      </div>
    </div>
  );
};

export default PrivateLayout;
