import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { _fetch } from "../config/Api";
import { base_url } from "../config/config";
import { ColorRing } from "react-loader-spinner";
const Singletrade = () => {
  const [uid, setUid] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [symbol, setSymbol] = useState("");
  const [mode, setMode] = useState("");
  const [timeex, setTimeex] = useState("GTC");
  const [type, setType] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [price, setPrice] = useState(0);
  const [leverage, setLeverage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [margin, setMargin] = useState("");

  const handledashboard = async (e) => {
    e.preventDefault();

    if (
      !uid ||
      !symbol ||
      !mode ||
      !timeex ||
      !type ||
      !quantity ||
      !leverage ||
      !margin
    ) {
      toast.dismiss();
      toast.error("Please enter the details");
      return;
    }
    setLoading(true);
    try {
      const requestData = {
        userId: [uid],
        symbol: symbol,
        mode: mode,
        timeInForce: timeex,
        type: type,
        marginType: margin,
        quantity: parseInt(quantity),
        price: parseFloat(price),
        leverage: parseInt(leverage),
      };

      let response = await _fetch(
        `${base_url}api/trader/placeForSingle`,
        "post",
        requestData
      );

      if (response.success === true) {
        setUid("");
        setName("");
        setEmail("");
        setSymbol("");
        setMode("");
        setTimeex("GTC");
        setType("");
        setQuantity(0);
        setLeverage(0);
        toast.dismiss();
        toast.success(response.message);
        // setData(response);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUIDChange = async (e) => {
    const newUid = e.target.value;
    setUid(newUid);
    if (newUid) {
      try {
        const requestData = { uid: newUid };
        let response = await _fetch(
          `${base_url}api/trader/fetchDetails`,
          "post",
          requestData
        );

        if (response.success === true) {
          setName(response.data.name);
          setEmail(response.data.email);
          // toast.dismiss();
          // toast.success(response.message);
        } else {
          setName("");
          setEmail("");
          // toast.dismiss()
          // toast.error(response.message);
        }
      } catch (error) {
        console.log("Error details", error);
      }
    } else {
      setName("");
      setName("");
    }
  };

  return (
    <>
      <div className="row mt-5 d-flex align-items-center justify-content-center">
        <div className="heading text-center mb-3">
          <h3>Single Trade</h3>
        </div>
        <div className="col-md-7">
          <form className="">
            <div className="form-group">
              <label>User Id</label>
              <input
                type="text"
                className="form-control"
                value={uid}
                onChange={handleUIDChange} // Call this on change
              />
              {name && email && (
                <div className="mt-2">
                  Name: {name} <br />
                  Email: {email}
                </div>
              )}{" "}
              {/* Display the fetched name directly under the UID input */}
            </div>
            <div className="form-group">
              <label className="">Symbol</label>
              <input
                type="text"
                className="form-control"
                value={symbol}
                onChange={(e) => setSymbol(e.target.value)}
              />
              {/* <label className=''>Mode</label>
                            <select

                                className="form-control input form-select"
                                aria-label="Default select example"
                                value={symbol}
                                onChange={(e) => setSymbol(e.target.value)}
                            >
                                <option >Symbol</option>
                                <option value={'SOLUSDT'}>SOLUSDT</option>
                            </select> */}
            </div>
            <div className="form-group">
              <label className="">Mode</label>
              <select
                className="form-control input form-select"
                aria-label="Default select example"
                value={mode}
                onChange={(e) => setMode(e.target.value)}
              >
                <option>mode</option>
                <option value={"BUY"}>Buy</option>
                <option value={"SELL"}> Sell</option>
              </select>
            </div>
            {/* <div className="form-group">
              <label className="">TimeInForce</label>

              <input
                type="text"
                className="form-control"
                value={timeex}
                onChange={(e) => setTimeex(e.target.value)}
                readOnly
              />
            </div> */}
            <div className="form-group">
              <label className="">Margin</label>

              <select
                className="form-control input form-select"
                aria-label="Default select example"
                value={margin}
                onChange={(e) => setMargin(e.target.value)}
              >
                <option>Select Margin</option>
                <option value={"CROSSED"}>Crossed</option>
                <option value={"ISOLATED"}>Isolated</option>
              </select>
            </div>
            <div className="form-group">
              <label className="">Type</label>

              <select
                className="form-control input form-select"
                aria-label="Default select example"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option>Type</option>
                <option value={"MARKET"}>Market</option>
                <option value={"LIMIT"}> limit</option>
              </select>
            </div>

            {/* <div className='form-group'>
                            <label className=''>quantity</label>

                            <input type='number' className='form-control' onChange={(e) => setQuantity(e.target.value)} />
                            <label className=''>leverage</label>

                            <input type='number' className='form-control' onChange={(e) => setLeverage(e.target.value)} />
                        </div> */}
            <div className="form-group">
              <label className="">quantity</label>
              <input
                type="number"
                className="form-control"
                min="0"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
              />
              <label className="">leverage</label>
              <input
                type="number"
                className="form-control"
                min="0"
                value={leverage}
                onChange={(e) => setLeverage(e.target.value)}
              />
              {type === "LIMIT" && (
                <>
                  <label className="">Price</label>
                  <input
                    type="number"
                    className="form-control"
                    min="0"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </>
              )}
            </div>
            <div className="row mt-3 text-center">
              <div className="col-12">
                {loading ? (
                  <ColorRing
                    height={65}
                    width={65}
                    colors={[
                      "#08d1d1",
                      "#08d1d1",
                      "#08d1d1",
                      "#08d1d1",
                      "#08d1d1",
                    ]}
                    ariaLabel="loading"
                  />
                ) : (
                  <button
                    className="p-2 bg-success text-white"
                    onClick={handledashboard}
                  >
                    Place Order
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Singletrade;
