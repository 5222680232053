import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { _fetch } from "../config/Api";
import { base_url } from "../config/config";
import { ColorRing } from "react-loader-spinner";
import dateFormat from "dateformat";
import { useNavigate } from "react-router-dom";
import { Alert, Pagination } from "antd";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";

const Paidmember = () => {
  const [data, setData] = useState();
  const [spotBalances, setSpotBalances] = useState({});
  const [futureBalances, setFutureBalances] = useState({});
  const [loading, setLoading] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSer, setpageSer] = useState(0);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [sponserId, SetSponserId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currency, setCurrency] = useState("");
  const [userId, setUserId] = useState("");
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
    userId: "",
    email: "",
    phonenumber: "",
    sponserId: "",
    name: "",
  });

  const applyFilters = () => {
    setFilters({
      startDate,
      endDate,
      userId,
      email,
      phonenumber,
      sponserId,
      name,
    });
  };

  const handledashboard = async (page = 1, pageSize = 10) => {
    try {
      const skip = (page - 1) * pageSize;
      setpageSer(skip);
      const requestData = {
        limit: pageSize,
        skip: skip,
        type: "paidUsers",
        from: filters.startDate,
        to: filters.endDate,
        uid: filters.userId,
        currency: currency,
        email: filters.email,
        phonenumber: filters.phonenumber,
        sponserId: filters.sponserId,
        name: filters.name,
      };
      let response = await _fetch(
        `${base_url}api/trader/members`,
        "post",
        requestData
      );

      if (response.success === true) {
        setData(response);
      } else {
        toast.dismiss();
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  };

  const getbalance = async (_id, type) => {
    setLoading((prevLoading) => ({
      ...prevLoading,
      [_id]: { ...prevLoading[_id], [type]: true },
    }));

    try {
      const requestData = {
        userId: _id,
        type: type,
      };

      let response = await _fetch(
        `${base_url}api/trader/walletBalance`,
        "post",
        requestData
      );
      console.log("response", response);

      if (response.success === true) {
        if (type === "SPOT") {
          setSpotBalances((prevBalances) => ({
            ...prevBalances,
            [_id]: response.data,
          }));
        } else if (type === "FUTURE") {
          setFutureBalances((prevBalances) => ({
            ...prevBalances,
            [_id]: response.data,
          }));
        }
        toast.dismiss();
        toast.success(`${type} balance fetched successfully`);
      } else {
        toast.dismiss();
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error details:", error);
    } finally {
      setLoading((prevLoading) => ({
        ...prevLoading,
        [_id]: { ...prevLoading[_id], [type]: false },
      }));
    }
  };

  useEffect(() => {
    handledashboard(currentPage, 10, filters);
  }, [currentPage, filters]);

  const handlePaginationChange = (pageOne) => {
    setCurrentPage(pageOne);
  };

  const handleStatusChange = (userId, selectedValue) => {
    let url = "";

    switch (selectedValue) {
      case "openview":
        url = `/View?id=${userId}`;
        break;

      default:
        return;
    }

    navigate(url);
  };

  const resetFilters = () => {
    setStartDate("");
    setEndDate("");
    setUserId("");
    setName("");
    setEmail("");
    setPhonenumber("");
    SetSponserId("");
    setFilters({
      startDate: "",
      endDate: "",
      userId: "",
      email: "",
      phonenumber: "",
      name: "",
      sponserId: "",
    });
    setCurrentPage(1);
    handledashboard(1, 10, {}); // Fetch all data again
  };

  const records = () => {
    if (data && data?.data && data?.data?.length > 0) {
      return data?.data?.map((row, i) => {
        const serialNumber = pageSer + i + 1;
        return (
          <tr>
            <th scope="row">{serialNumber}</th>
            <td>{row?.userId}</td>
            <td>{row?.name}</td>
            <td>{row?.email}</td>
            <td>{row?.phonenumber}</td>
            <td>{row?.packageAmount}</td>
            <td className="text-success">{row?.sponserId}</td>
            <td>{dateFormat(row.createdAt, "yyyy-mm-dd")}</td>
            <td>${(row?.balance).toFixed(4)}</td>
            <td className="table-btn">
              <button
                className="btn btn-primary"
                onClick={() => getbalance(row._id, "FUTURE")}
              >
                {futureBalances[row._id]?.walletBalance
                  ? `$${parseFloat(
                      futureBalances[row._id].walletBalance
                    ).toFixed(4)}`
                  : "Future"}
              </button>
            </td>
            <td className="table-btn-2">
              <button
                className="btn btn-primary wallet-btn"
                onClick={() => getbalance(row._id, "SPOT")}
              >
                {spotBalances[row._id]?.free
                  ? `$${parseFloat(spotBalances[row._id].free).toFixed(4)}`
                  : "Spot"}
              </button>
              {/* <button className="btn btn-primary" onClick={() => handleStatusChange(row._id, 'openview')}>View</button> */}
            </td>
            <td className="table-btn-3">
              <button
                className="btn btn-primary view-btn"
                onClick={() => handleStatusChange(row._id, "openview")}
              >
                View
              </button>
              {/* <button className="btn btn-primary" onClick={() => handleStatusChange(row._id, 'openview')}>View</button> */}
            </td>
          </tr>
        );
      });
    } else {
      return <div>Data Not Found</div>;
    }
  };

  const getExportData = async () => {
    try {
      const exportRequest = {
        limit: 1000,
        skip: 0,
        type: "paidUsers",
        from: filters.startDate,
        to: filters.endDate,
        uid: filters.userId,
        name: filters.name,
        email: filters.email,
        phonenumber: filters.phonenumber,
        sponserId: filters.sponserId,
        export: true,
      };

      const response = await _fetch(
        `${base_url}api/trader/members`,
        "post",
        exportRequest
      );

      if (!response.success) {
        throw new Error(response.message || "Failed to fetch export data");
      }

      return response.data;
    } catch (error) {
      console.error("Export data fetch error:", error);
      toast.dismiss();
      toast.error("Failed to get export data");
      throw error;
    }
  };

  const exportToPDF = async () => {
    try {
      const data = await getExportData();

      const doc = new jsPDF();

      const tableColumns = [
        "S No.",
        "UserId",
        "Name",
        "Email",
        "Phone",
        "Package Amount",
        "Sponsor ID",
        "Balance",
        "Register Date",
      ];

      const tableRows = data.map((item, index) => [
        index + 1,
        item.userId,
        item.name,
        item.email,
        item.phonenumber,
        item.packageAmount || "Not Applied",
        item.sponserId,
        item.balance,
        dateFormat(item.createdAt, "yyyy-mm-dd"),
      ]);

      doc.text("Trader Members Report", 14, 15);

      doc.autoTable({
        head: [tableColumns],
        body: tableRows,
        startY: 20,
        theme: "grid",
        styles: { fontSize: 8 },
      });

      doc.save("trader-members.pdf");
      toast.dismiss();
      toast.success("PDF downloaded successfully");
    } catch (error) {
      console.error("PDF export error:", error);
      toast.dismiss();
      toast.error("Failed to export PDF");
    }
  };

  const exportToExcel = async () => {
    try {
      const data = await getExportData();

      const excelData = data.map((item, index) => ({
        "S No.": index + 1,
        "User ID": item.userId,
        Name: item.name,
        Email: item.email,
        Phone: item.phonenumber,
        "Package Amount": item.packageAmount || "Not Applied",
        "Sponsor ID": item.sponserId,
        Balance: item.balance,
        "Register Date": dateFormat(item.createdAt, "yyyy-mm-dd"),
      }));

      const worksheet = XLSX.utils.json_to_sheet(excelData);

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Trader Members");

      XLSX.writeFile(workbook, "trader-members.xlsx");

      toast.success("Excel file downloaded successfully");
    } catch (error) {
      console.error("Excel export error:", error);
      toast.error("Failed to export Excel");
    }
  };

  return (
    <>
      <div className="section2">
        <div className="container container-trade p-0">
          <div className="">
            <div className="container container-trade2 mt-2 p-1">
              <div className="text-right1 p-3">
                <button className="btn btn-primary me-2" onClick={exportToPDF}>
                  Export PDF
                </button>
                <button className="btn btn-primary" onClick={exportToExcel}>
                  Export Excel
                </button>
              </div>
              <div className="card back-card">
                <div className="main-12">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 p-1 m-auto">
                      <input
                        type="date"
                        className="form-control"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 p-1 m-auto">
                      <input
                        type="date"
                        className="form-control"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                    {/* <div className='col-lg-4 col-md-4 col-sm-6 col-6 p-1'>
                        <select class="form-select" aria-label="Default select example">
                            <option selected>UserId</option>
                            <option value="1">Email</option>
                            <option value="2">Phone</option>
                            <option value="3">sponserId</option>
                        </select>
                    </div> */}
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 p-1 m-auto">
                      <div className="search-btn">
                        <input
                          type="email"
                          placeholder="email Search"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1 m-auto">
                      <div className="search-btn">
                        <input
                          type="text"
                          placeholder="User Id Search"
                          value={userId}
                          onChange={(e) => setUserId(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1 m-auto">
                      <div className="search-btn">
                        <input
                          type="text"
                          placeholder="Name Search"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 p-1 m-auto">
                      <div className="search-btn">
                        <input
                          type="number"
                          placeholder="phone number Search"
                          value={phonenumber}
                          onChange={(e) => setPhonenumber(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1 m-auto">
                      <div className="search-btn">
                        <input
                          type="text"
                          placeholder="Sponsor Id Search"
                          value={sponserId}
                          onChange={(e) => SetSponserId(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1">
                      <div className="reset-btn">
                        <button className="apply-btn" onClick={applyFilters}>
                          Apply Filters
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1">
                      <div className="place-2">
                        <button
                          onClick={resetFilters}
                          className="btn btn-secondary"
                        >
                          Reset Filters
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* <div className='row'>

                    <div className='col-lg-4 col-md-4 col-sm-6 col-6 p-1'>
                      <input type='date' className='form-control' />
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-6 p-1'>
                      <input type='date' className='form-control' />
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-6 p-1'>
                      <select class="form-select" aria-label="Default select example">
                        <option selected>UserId</option>
                        <option value="1">Email</option>
                        <option value="2">Phone</option>
                        <option value="3">sponserId</option>
                      </select>
                    </div>


                    <div className='col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1'>
                      <div className='search-btn'>
                        <input type="text" placeholder="User Id Search" />
                      </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1'>
                      <div className='reset-btn'>
                        <button className='apply-btn' >Apply Filters</button>
                      </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1'>
                      <div className='place-2'>
                        <button className="btn btn-secondary">Reset Filters</button>
                      </div>
                    </div>


                  </div> */}
                </div>
              </div>
              <h4 className="text-right1 p-3 text-primary">
                Total Members: {data?.count}
              </h4>
            </div>
            <div className="container container-trade2 mt-2 p-1">
              <div className="transactions dist">
                <div className="card border-primary">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">UserId</th>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Package Amount</th>
                        <th scope="col">Sponsor ID</th>
                        <th scope="col">Activation Date</th>
                        <th scope="col">Balance</th>
                        <th scope="col">Future balance </th>
                        <th scope="col">Spot balance</th>

                        <th scope="col">view </th>
                      </tr>
                    </thead>
                    <tbody>{records()}</tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="pagination mt-3">
              <Pagination
                style={{ cursor: "pointer" }}
                className="mx-auto"
                current={currentPage || 1}
                total={data?.count || 0}
                pageSize={10}
                onChange={handlePaginationChange}
                showSizeChanger={false}
                showQuickJumper={false}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Paidmember;
