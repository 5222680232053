import React from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";

import { _fetch } from "../config/Api";
import { base_url } from "../config/config";
import { useState, useEffect } from "react";
import { ColorRing } from "react-loader-spinner";
import logo from "../Assets/logo.png";
import businessman from "../Assets/businessman.jpg";
function Verfication() {
  const { state } = useLocation();
  console.log("state", state);

  const [email, setEmail] = useState(state?.email || "");
  const [Otp, setOtp] = useState();
  const [loading, setLoading] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const navigate = useNavigate();
  const handleSignUp = async (e) => {
    e.preventDefault();
    if (!email || !Otp) {
      toast.dismiss();
      toast.error("Please Enter the details");
      return;
    }
    setLoadings(true);
    try {
      const signupData = {
        email: email,
        otp: Otp,
      };
      let response = await _fetch(
        `${base_url}api/trader/verifySignin`,
        "POST",
        signupData,
        {}
      );

      if (response.success === true) {
        localStorage.setItem("token", response.token);
        toast.dismiss();
        toast.success(response.message);
        navigate("/dashboard");
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    } finally {
      setLoadings(false);
    }
  };
  const handleresend = async (e) => {
    e.preventDefault();
    if (!email) {
      toast.dismiss();
      toast.error("Please enter the details");
      return;
    }
    setLoading(true);
    try {
      const signupData = {
        email: email,
        type: "resendOtp",
      };
      let response = await _fetch(
        `${base_url}api/trader/resendOtp`,
        "POST",
        signupData,
        {}
      );

      if (response.success === true) {
        toast.dismiss();
        toast.success(response.message);
      } else {
        toast.dismiss();
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div className="conatiner-fluid">
        <div className="d-flex justify-content-center align-items-center h-100">
          <div className="sign-page ios ">
            <div className="heading text-center ">
              <NavLink to="/">
                <img className="welcome" src={logo} alt="" />
              </NavLink>
              <img className="image_border mt-3" src={businessman} alt="..." />
              <h3 className="H_3 email">Account Verification</h3>
            </div>
            <form>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Email{" "}
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="off"
                  className="form-control"
                  aria-describedby="emailHelp"
                  readOnly
                />
              </div>

              <div className="mb-3">
                <label htmlFor="exampleInputPassword1" className="form-label">
                  OTP
                </label>
                <input
                  type="text"
                  id="sponser"
                  onChange={(e) => setOtp(e.target.value)}
                  autoComplete="off"
                  className="form-control"
                />
              </div>
              <div className="butt">
                <button
                  type="submit"
                  className="btn iop btn-primary"
                  onClick={handleSignUp}
                >
                  {loadings ? (
                    <i class="text-white spinner-border"></i>
                  ) : (
                    "Submit"
                  )}
                </button>
                <button
                  type="submit"
                  className="btn iop btn-primary m"
                  style={{ marginLeft: "150px" }}
                  onClick={handleresend}
                >
                  {loading ? (
                    <i class="text-white spinner-border"></i>
                  ) : (
                    "Resend OTP"
                  )}
                </button>
              </div>
              <div></div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Verfication;
