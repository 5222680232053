import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { _fetch } from "../config/Api";
import { base_url } from "../config/config";
import { ColorRing } from "react-loader-spinner";
import dateFormat from "dateformat";
import { Pagination } from "antd";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";

const WorkingToAddress = () => {
  const [userId, setUserId] = useState("");
  const [status, SetStatus] = useState("PENDING");
  const [data, setData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSer, setpageSer] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [email, setEmail] = useState("");
  const [sponserId, SetSponserId] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [currency, setCurrency] = useState("");
  const [name, setName] = useState("");
  const [leverage, setLeverage] = useState("");
  const [transactionType, setTransactionType] = useState("");
  const [modalData, setModalData] = useState(null);
  const [remarks, setRemarks] = useState("");
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
    userId: "",
    email: "",
    phonenumber: "",
    sponserId: "",
    name: "",
    currency: "",
    leverage: "",
  });
  const applyFilters = () => {
    setFilters({
      startDate,
      endDate,
      userId,
      email,
      currency,
      leverage,
      phonenumber,
      sponserId,
      transactionType,
      name,
    });
  };
  const resetFilters = () => {
    setStartDate("");
    setEndDate("");
    setUserId("");
    setName("");
    setEmail("");
    setPhonenumber("");
    SetSponserId("");
    setCurrency("");
    setTransactionType("");
    setLeverage("");
    setFilters({
      startDate: "",
      name: "",
      endDate: "",
      userId: "",
      email: "",
      phonenumber: "",
      sponserId: "",
      currency: "",
      leverage: "",
      transactionType: "",
    });
    setCurrentPage(1);
    handledashboard(1, 10, {}); // Fetch all data again
  };

  const openModal = (row, actionStatus) => {
    setModalData({ _id: row._id, actionStatus });
    setRemarks("");
  };

  const handleModalSubmit = async () => {
    if (!remarks.trim()) {
      toast.error("Please enter remarks.");
      return;
    }

    try {
      const payload = {
        Id: modalData._id,
        remarks,
        status: modalData.actionStatus,
      };

      const response = await _fetch(
        `${base_url}api/trader/manageWithraw`,
        "post",
        payload
      );

      if (response.success) {
        toast.dismiss();
        toast.success(response.message);
        setModalData(null);
        handledashboard(currentPage, 10);
      } else {
        toast.dismiss();
        toast.error(response.message || "Something went wrong.");
      }
    } catch (error) {
      toast.dismiss();
      console.error("Error submitting action:", error);
      toast.error("Failed to submit action.");
    }
  };

  const handledashboard = async (page = 1, pageSize = 10) => {
    try {
      const skip = (page - 1) * pageSize;
      setpageSer(skip);
      const requestData = {
        limit: pageSize,
        skip: skip,
        status,
        // name: filters.name,
        // uid: filters.userId,
        // from: filters.startDate,
        // to: filters.endDate,
      };

      let response = await _fetch(
        `${base_url}api/trader/getWithraws`,
        "post",
        requestData
      );

      if (response.success === true) {
        setData(response);
      } else {
        toast.dismiss();
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  };

  useEffect(() => {
    handledashboard(currentPage, 10);
  }, [currentPage, status]);
  const handlePaginationChange = (pageOne) => {
    setCurrentPage(pageOne);
  };

  const records = () => {
    if (data && data?.data && data?.data?.length > 0) {
      return data?.data?.map((row, i) => {
        const serialNumber = pageSer + i + 1;
        return (
          <tr className="table-row" key={i}>
            <td className="tabel-text w inter">{serialNumber}</td>
            <td className="tabel-text w inter">{row?.userId.userId}</td>
            <td className="tabel-text w inter">{row?.userId.name}</td>
            <td className="tabel-text w inter">{row?.traderId}</td>
            <td className="tabel-text w inter text-success">
              {row?.tokenName}
            </td>
            <td className="tabel-text w inter">{row?.walletAddress}</td>
            <td className="tabel-text w inter">{row?.status}</td>
            <td className="tabel-text w inter">${row?.amount}</td>
            <td className="tabel-text w inter">${row?.payAbleAmount}</td>
            {/* <td
              className={`tabel-text w inter ${
                row?.processStatus === true ? "text-success" : "text-danger"
              }`}
            >
              {row?.processStatus ? "True" : "False"}
            </td> */}
            <td className="tabel-text w inter">
              {dateFormat(row.createdAt, "yyyy-mm-dd, HH:mm:ss")}
            </td>
            {status && status === "PENDING" ? (
              <td className="tabel-text w inter d-flex flex-wrap gap-2">
                <button
                  className="btn btn-success"
                  type="button"
                  onClick={() => openModal(row, "APPROVED")}
                >
                  APPROVE
                </button>
                <button
                  className="btn btn-danger"
                  type="button"
                  onClick={() => openModal(row, "REJECTED")}
                >
                  REJECT
                </button>
              </td>
            ) : (
              ""
            )}
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="13">Data Not Found</td>
        </tr>
      );
    }
  };

  return (
    <>
      <div className="container container-trade p-0">
        <div className="">
          <div className="container container-trade2 mt-2 p-1">
            <h1 className="text-info text-center">Withdraw Requests</h1>
            <div className="row">
              <div className="col-md-4 col-12">
                <h4>Select Status</h4>
                <select
                  className="form-select"
                  onChange={(e) => SetStatus(e.target.value)}
                >
                  <option value={"PENDING"}>PENDING</option>
                  <option value={"APPROVED"}>APPROVED</option>
                  <option value={"REJECTED"}>REJECTED</option>
                </select>
              </div>
            </div>
            <h4 className="text-right1 p-3 text-primary">
              Withdraw Requests: {data?.count}
            </h4>
          </div>
          <div className="container container-trade2 mt-2 p-1">
            <div className="transactions">
              {/* {records()} */}
              <div className="card border-primary">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">UserId</th>
                      <th scope="col">Name</th>
                      <th scope="col">Trader Id</th>
                      <th scope="col">Token Name</th>
                      <th scope="col">walletAddress</th>
                      <th scope="col">Status</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Payable Amount</th>
                      {/* <th scope="col">Process Status</th> */}
                      <th scope="col">Date</th>
                      {status && status === "PENDING" ? (
                        <th scope="col">Action</th>
                      ) : (
                        ""
                      )}
                    </tr>
                  </thead>
                  <tbody>{records()}</tbody>
                </table>
              </div>
            </div>
            <div className="pagination mt-3">
              <Pagination
                style={{ cursor: "pointer" }}
                className="mx-auto"
                current={currentPage || 1}
                total={data?.count || 0}
                pageSize={10}
                onChange={handlePaginationChange}
                showSizeChanger={false}
                showQuickJumper={false}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      {modalData && (
        <div className="modal show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add Remarks</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setModalData(null)}
                ></button>
              </div>
              <div className="modal-body">
                <textarea
                  className="form-control"
                  rows="4"
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                  placeholder="Enter remarks here..."
                ></textarea>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setModalData(null)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleModalSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WorkingToAddress;
