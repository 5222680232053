import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Pagination } from "antd";
import { ColorRing } from "react-loader-spinner";
import { _fetch } from "../config/Api";
import { base_url } from "../config/config";
import dateFormat from "dateformat";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";

const Activity = () => {
  const [data, setData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSer, setPageSer] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [userId, setUserId] = useState("");
  const [name, setName] = useState("");
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
    userId: "",
    name: "",
  });

  const handledashboard = async (page = 1, pageSize = 10, filters = {}) => {
    try {
      const skip = (page - 1) * pageSize;
      setPageSer(skip);
      const requestData = {
        limit: pageSize,
        skip: skip,
        from: filters.startDate,
        to: filters.endDate,
        uid: filters.userId,
        name: filters.name,
      };

      let response = await _fetch(
        `${base_url}api/trader/activitys`,
        "post",
        requestData
      );

      if (response.success === true) {
        setData(response);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  };

  const handlePaginationChange = (pageOne) => {
    setCurrentPage(pageOne);
  };

  const applyFilters = () => {
    setFilters({
      startDate,
      endDate,
      userId,
      name,
    });
  };

  // Reset filters to default state
  const resetFilters = () => {
    setStartDate("");
    setEndDate("");
    setUserId("");
    setName("");
    setFilters({
      startDate: "",
      endDate: "",
      userId: "",
      name: "",
    });
    setCurrentPage(1);
    handledashboard(1, 10, {}); // Fetch all data again
  };

  useEffect(() => {
    handledashboard(currentPage, 10, filters);
  }, [currentPage, filters]); // Only trigger when filters or currentPage change

  const records = () => {
    if (data && data?.data && data?.data?.length > 0) {
      return data?.data?.map((row, i) => {
        const serialNumber = pageSer + i + 1;
        return (
          <tr className="table-row" key={i}>
            <td className="tabel-text w inter">{serialNumber}</td>
            <td className="tabel-text w inter text-success">
              {row?.userId?.userId}
            </td>
            <td className="tabel-text w inter">{row?.userId?.name}</td>
            <td className="tabel-text w inter">{row?.activity}</td>
            <td className="tabel-text w inter">{row?.discription}</td>
            <td className="tabel-text w inter text-danger">{row?.message}</td>
            <td>{dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="7">Data Not Found</td>
        </tr>
      );
    }
  };

  const getExportData = async () => {
    try {
      const exportRequest = {
        limit: 1000,
        skip: 0,
        from: filters.startDate,
        to: filters.endDate,
        uid: filters.userId,
        name: filters.name,
        export: true,
      };

      const response = await _fetch(
        `${base_url}api/trader/activitys`,
        "post",
        exportRequest
      );

      if (!response.success) {
        throw new Error(response.message || "Failed to fetch export data");
      }

      return response.data;
    } catch (error) {
      console.error("Export data fetch error:", error);
      toast.dismiss();
      toast.error("Failed to get export data");
      throw error;
    }
  };

  const exportToPDF = async () => {
    try {
      const data = await getExportData();

      const doc = new jsPDF();

      const tableColumns = [
        "S No.",
        "UserId",
        "Name",
        "Activity",
        "Description",
        "Message",
        "Date",
      ];

      const tableRows = data.map((item, index) => [
        index + 1,
        item.userId?.userId,
        item.userId?.name,
        item.activity,
        item.discription,
        item.message,
        dateFormat(item.createdAt, "yyyy-mm-dd"),
      ]);

      doc.text("Activity Report", 14, 15);

      doc.autoTable({
        head: [tableColumns],
        body: tableRows,
        startY: 20,
        theme: "grid",
        styles: { fontSize: 8 },
      });

      doc.save("activity-report.pdf");
      toast.dismiss();
      toast.success("PDF downloaded successfully");
    } catch (error) {
      console.error("PDF export error:", error);
      toast.dismiss();
      toast.error("Failed to export PDF");
    }
  };

  const exportToExcel = async () => {
    try {
      const data = await getExportData();
      const excelData = data.map((item, index) => ({
        "S No.": index + 1,
        "UserId": item.userId?.userId,
        "Name": item.userId?.name,
        "Activity": item.activity,
        "Description": item.discription,
        "Message": item.message,
        "Date": dateFormat(item.createdAt, "yyyy-mm-dd"),
      }));

      const worksheet = XLSX.utils.json_to_sheet(excelData);

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        "Activity Report"
      );

      XLSX.writeFile(workbook, "activity-report.xlsx");
      toast.dismiss();
      toast.success("Excel file downloaded successfully");
    } catch (error) {
      console.error("Excel export error:", error);
      toast.dismiss();
      toast.error("Failed to export Excel");
    }
  };

  return (
    <>
      <div className="container container-trade p-0">
        <div className="container container-trade2 mt-2 p-1">
          <h1 className="text-primary text-center">Activities</h1>
          <div className="text-right1 p-3">
            <button className="btn btn-primary me-2" onClick={exportToPDF}>
              Export PDF
            </button>
            <button className="btn btn-primary" onClick={exportToExcel}>
              Export Excel
            </button>
          </div>
          <div className="transactions">
            <div className="container container-trade2 mt-2 p-1">
              <div className="card back-card">
                <div className="main-12">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 p-1">
                      <input
                        type="date"
                        className="form-control m-auto"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 p-1">
                      <input
                        type="date"
                        className="form-control m-auto"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1">
                      <div className="search-btn">
                        <input
                          type="text"
                          placeholder="User Id Search"
                          value={userId}
                          onChange={(e) => setUserId(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1 m-auto">
                      <div className="search-btn">
                        <input
                          type="text"
                          placeholder="Name Search"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1">
                      <div className="reset-btn">
                        <button className="apply-btn" onClick={applyFilters}>
                          Apply Filters
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1">
                      <div className="place-2">
                        <button
                          onClick={resetFilters}
                          className="btn btn-secondary"
                        >
                          Reset Filters
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h4 className="text-right1 p-3 text-primary">
                Total Activities: {data?.count}
              </h4>
            </div>

            <div className="card border-primary mt-2">
              <table className="table table-responsive">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">UserId</th>
                    <th scope="col">Name</th>
                    <th scope="col">Activity</th>
                    <th scope="col">Description</th>
                    <th scope="col">Message</th>
                    <th scope="col">Date</th>
                  </tr>
                </thead>
                <tbody>{records()}</tbody>
              </table>
            </div>

            <div className="pagination mt-4">
              <Pagination
                style={{ cursor: "pointer" }}
                className="mx-auto"
                current={currentPage || 1}
                total={data?.count || 0}
                pageSize={10}
                onChange={handlePaginationChange}
                showSizeChanger={false}
                showQuickJumper={false}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Activity;
