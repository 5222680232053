import React, { useEffect, useState } from 'react';
import _fetch from '../config/Api';
import { base_url } from '../config/config';
import girlb from '../Assets/girlb.png'

const Admin = () => {
  const [userInfo, setUserInfo] = useState('');
  const [data, setData] = useState({});
  const [name, setName] = useState({});


  useEffect(() => {
    handleData();
  }, []);

  const handleData = async () => {
    try {
      // let response = await _fetch(`${base_url}admin/users`, 'POST', {}, {});
      let response = await _fetch(`${base_url}api/trader/dashboard`, 'GET', {}, {});

      // console.log("response", response);
      if (response.success === true) {
        setUserInfo(response.data);

        setName(response?.data.trader);
        // console.log(response.data);

      } else {
        console.log("error", response.message);
      }
    } catch (error) {
      console.error('Error details:', error);
    }
  };

  console.log('userInfo', userInfo);

  return (
    <>

      <div className="container container-trade p-0">
        {/* <div className=""> */}
        <div className="container container-trade2 mt-2 p-1">
          <div className="row mb-2 starter-row m-auto ">
            <div className="heading-main">
              <h1 className='m-0 text-dark main-heading'>Trader Dashboard</h1>

            </div>

          </div>
          {/* <div className="row mb-2 starter-row"> */}
          {/* <div className="col-sm-4 heading-main"> */}
          {/* <h1 className='m-0 text-light main-heading'>Admin Dashboard</h1> */}


          {/* </div> */}

          {/* </div> */}



          {/* <div className="container mt-3"> */}
          <div className="rounded-2 mt-3" style={{ backgroundImage: `url(${girlb})` }}>
            <div className="row p-1 p-md-2 uio text-light m-auto">
              <div className="col-lg-12">
                <div className="iq-card iq-card-block iq-card-stretch iq-card-height iq-bg-danger">
                  <div className="iq-card-body  iq-box-relative">
                    {/* <img
                      className="rounded img-fluid" src={girlb}></img> */}
                    {/* <img src={girlb} className="rounded img-fluid" alt="Phone Logo" /> */}
                    <div className="itd ">

                      <h4 class="d-block mb-3 text-black text-end">Welcome To Future Trade </h4>
                      {/* <div className="box-image float-right">
                      <img
                        className="rounded img-fluid"
                        src="https://templates.iqonic.design/server360/html/images/page-img/37.png"
                        alt="profile"
                      />
                    </div> */}
                      {/* <div className="p-3">
                      <h5 className="mb-0 admin">{name?.name || "N/A"}</h5>
                    </div>
                    <div className="p-3">
                      <h6 className="mb-0 wh12h">{name?.traderId || "N/A"}</h6>
                    </div>
                    <div className="p-3 ">
                      <h5 className="mb-0 jstech ">{name?.email || "N/A"}</h5>
                    </div> */}
                      <div className="text-dark text-end">
                        <h6 className='mb-0 balance'>
                          Balance: $ {data?.walletBalance ? Number(data?.walletBalance).toFixed(4) : "0"} {data?.asset || ""}
                        </h6>
                      </div>

                    </div>
                  </div>
                </div>
              </div>


              {/* <div className='col-lg-12 col-md-6 col-sm-6 col-6'>
              <div className="">
                <div className="itd ">
                  <h4 class="d-block mb-3 text-black">Welcome To </h4>
                  <div className="p-3">
                    <h5 className="mb-0 admin">{name?.name || "N/A"}</h5>
                  </div>
                  <div className="p-3">
                    <h6 className="mb-0 wh12h">{name?.traderId || "N/A"}</h6>
                  </div>
                  <div className="p-3 ">
                    <h5 className="mb-0 jstech ">{name?.email || "N/A"}</h5>
                  </div>
                  <div className="p-3">
                    <h6 className='mb-0 balance'>
                      Balance: {data?.walletBalance ? Number(data?.walletBalance).toFixed(4) : "0"} {data?.asset || ""}
                    </h6>
                  </div>

                </div>
              </div>
            </div> */}

              {/* <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
              <div className="cr ">
                <div className="p-3">
                  <h6>members</h6>
                  <p>{userInfo?.members}</p>
                </div>

              </div>
            </div> */}

            </div>
          </div>

          <div className='col-lg-12 col-sm-12 mt-4'>
            <marquee width="100%" direction="left" height="30px">
              Bot activation is free from October 29th to November 15th. Users with $1200 in their account qualify for a 4-day, 3-night Goa trip.
            </marquee>
          </div>

          <div className='row mt-1 p-1'>
            {/* <div className="col-auto icon-box p-0"> */}
            {/* <div className="icon-box1 "> */}
            <div className='col-lg-3 col-md-3 col-sm-6 col-12 p-2'>
              <div className="box">
                <div class="position-relative shape-block">
                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" focusable="false" class="chakra-icon css-7zspnv" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M4 9h4v11H4zM16 13h4v7h-4zM10 4h4v16h-4z"></path>
                  </svg>

                </div>
                <div className='ms-2'>
                  <h6 className='trade-text'>Total Members</h6>
                  <p className='trade-parg'>{userInfo?.members}</p>
                </div>



              </div>
            </div>
            <div className='col-lg-3 col-md-3 col-sm-6 col-12 p-2'>
              <div className="box-1">
                <div class="position-relative shape-block">
                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" focusable="false" class="chakra-icon css-7zspnv-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M4 9h4v11H4zM16 13h4v7h-4zM10 4h4v16h-4z"></path>
                  </svg>
                </div>
                <div className='ms-2'>
                  <h6 className='trade-text'>Active Members</h6>
                  <p className='trade-parg'>{userInfo?.paidMembers}</p>
                </div>


              </div>
            </div>

            <div className='col-lg-3 col-md-3 col-sm-6 col-12 p-2'>
              <div className="box-2">
                <div class="position-relative shape-block">
                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" focusable="false" class="chakra-icon css-7zspnv-3" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M4 9h4v11H4zM16 13h4v7h-4zM10 4h4v16h-4z"></path>
                  </svg>
                </div>
                <div className='ms-2'>
                  <h6 className='trade-text'>Deposited Fund</h6>
                  <p className='trade-parg'>{userInfo?.depositRecords}</p>
                </div>


              </div>
            </div>

            <div className='col-lg-3 col-md-3 col-sm-6 col-12 p-2'>
              <div className="box-3">
                <div class="position-relative shape-block">
                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" focusable="false" class="chakra-icon css-7zspnv-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M4 9h4v11H4zM16 13h4v7h-4zM10 4h4v16h-4z"></path>
                  </svg>
                </div>
                <div className='ms-2'>
                  <h6 className='trade-text'>Withdrawal</h6>
                  <p className='trade-parg'>{userInfo?.withrawRecords}</p>
                </div>


              </div>
            </div>





            {/* <div className="box">
              <div class="position-relative shape-block">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" focusable="false" class="chakra-icon css-7zspnv" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M4 9h4v11H4zM16 13h4v7h-4zM10 4h4v16h-4z"></path>
                </svg>
              </div>
              <div className='ms-2'>
                <h6>members</h6>
                <p>{userInfo?.members}</p>
              </div>


            </div>

            <div className="box">
              <div class="position-relative shape-block">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" focusable="false" class="chakra-icon css-7zspnv" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M4 9h4v11H4zM16 13h4v7h-4zM10 4h4v16h-4z"></path>
                </svg>
              </div>
              <div className='ms-2'>
                <h6>members</h6>
                <p>{userInfo?.members}</p>
              </div>


            </div>

            <div className="box">
              <div class="position-relative shape-block">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" focusable="false" class="chakra-icon css-7zspnv" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M4 9h4v11H4zM16 13h4v7h-4zM10 4h4v16h-4z"></path>
                </svg>
              </div>
              <div className='ms-2'>
                <h6>members</h6>
                <p>{userInfo?.members}</p>
              </div>


            </div>

            <div className="box">
              <div class="position-relative shape-block">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" focusable="false" class="chakra-icon css-7zspnv" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M4 9h4v11H4zM16 13h4v7h-4zM10 4h4v16h-4z"></path>
                </svg>
              </div>
              <div className='ms-2'>
                <h6>members</h6>
                <p>{userInfo?.members}</p>
              </div>


            </div> */}

            {/* <div className="box">
              <div class="position-relative shape-block">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" focusable="false" class="chakra-icon css-7zspnv" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M4 9h4v11H4zM16 13h4v7h-4zM10 4h4v16h-4z"></path>
                </svg>
              </div>
              <div className='ms-2'>
                <h6>members</h6>
                <p>{userInfo?.members}</p>
              </div>


            </div> */}



          </div>
          <div className='row mt-1 p-1'>
            {/* <div className="col-auto icon-box p-0"> */}
            {/* <div className="icon-box1 "> */}
            <div className='col-lg-3 col-md-3 col-sm-6 col-12 p-2'>
              <div className="box-3">
                <div class="position-relative shape-block">
                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" focusable="false" class="chakra-icon css-7zspnv" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M4 9h4v11H4zM16 13h4v7h-4zM10 4h4v16h-4z"></path>
                  </svg>
                </div>
                <div className='ms-2'>
                  <h6 className='trade-text'>Total Members</h6>
                  <p className='trade-parg'>{userInfo?.members}</p>
                </div>


              </div>
            </div>
            <div className='col-lg-3 col-md-3 col-sm-6 col-12 p-2'>
              <div className="box-2">
                <div class="position-relative shape-block">
                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" focusable="false" class="chakra-icon css-7zspnv-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M4 9h4v11H4zM16 13h4v7h-4zM10 4h4v16h-4z"></path>
                  </svg>
                </div>
                <div className='ms-2'>
                  <h6 className='trade-text'>Active Members</h6>
                  <p className='trade-parg'>{userInfo?.paidMembers}</p>
                </div>


              </div>
            </div>

            <div className='col-lg-3 col-md-3 col-sm-6 col-12 p-2'>
              <div className="box-1">
                <div class="position-relative shape-block">
                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" focusable="false" class="chakra-icon css-7zspnv-3" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M4 9h4v11H4zM16 13h4v7h-4zM10 4h4v16h-4z"></path>
                  </svg>
                </div>
                <div className='ms-2'>
                  <h6 className='trade-text'>Deposited Fund</h6>
                  <p className='trade-parg'>{userInfo?.depositRecords}</p>
                </div>
              </div>
            </div>

            <div className='col-lg-3 col-md-3 col-sm-6 col-12 p-2'>
              <div className="box">
                <div class="position-relative shape-block">
                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" focusable="false" class="chakra-icon css-7zspnv-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M4 9h4v11H4zM16 13h4v7h-4zM10 4h4v16h-4z"></path>
                  </svg>
                </div>
                <div className='ms-2'>
                  <h6 className='trade-text'>Withdrawal</h6>
                  <p className='trade-parg'>{userInfo?.withrawRecords}</p>
                </div>
              </div>
            </div>



            {/* <div className="box">
              <div class="position-relative shape-block">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" focusable="false" class="chakra-icon css-7zspnv" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M4 9h4v11H4zM16 13h4v7h-4zM10 4h4v16h-4z"></path>
                </svg>
              </div>
              <div className='ms-2'>
                <h6>members</h6>
                <p>{userInfo?.members}</p>
              </div>


            </div>

            <div className="box">
              <div class="position-relative shape-block">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" focusable="false" class="chakra-icon css-7zspnv" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M4 9h4v11H4zM16 13h4v7h-4zM10 4h4v16h-4z"></path>
                </svg>
              </div>
              <div className='ms-2'>
                <h6>members</h6>
                <p>{userInfo?.members}</p>
              </div>


            </div>

            <div className="box">
              <div class="position-relative shape-block">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" focusable="false" class="chakra-icon css-7zspnv" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M4 9h4v11H4zM16 13h4v7h-4zM10 4h4v16h-4z"></path>
                </svg>
              </div>
              <div className='ms-2'>
                <h6>members</h6>
                <p>{userInfo?.members}</p>
              </div>


            </div>

            <div className="box">
              <div class="position-relative shape-block">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" focusable="false" class="chakra-icon css-7zspnv" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M4 9h4v11H4zM16 13h4v7h-4zM10 4h4v16h-4z"></path>
                </svg>
              </div>
              <div className='ms-2'>
                <h6>members</h6>
                <p>{userInfo?.members}</p>
              </div>


            </div> */}

            {/* <div className="box">
              <div class="position-relative shape-block">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" focusable="false" class="chakra-icon css-7zspnv" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M4 9h4v11H4zM16 13h4v7h-4zM10 4h4v16h-4z"></path>
                </svg>
              </div>
              <div className='ms-2'>
                <h6>members</h6>
                <p>{userInfo?.members}</p>
              </div>


            </div> */}



          </div>
          <div className="col-xl-12 col-lg-12 col-md-12">
            {" "}
            <div className="card">
              {" "}
              <div className="card-header d-flex justify-content-between">
                {" "}
                <h3 className="card-title">Invoice Summary</h3>{" "}
                <div className="d-flex flex-wrap gap-2">
                  {" "}
                  <div>
                    {" "}
                    <input
                      className="form-control form-control-sm py-0 fs-12"
                      type="text"
                      placeholder="Search Here"
                      aria-label=".form-control-sm example"
                    />{" "}
                  </div>{" "}

                </div>{" "}
              </div>{" "}
              <div className="card-body pb-0">
                {" "}
                <div className="table-responsive">
                  {" "}
                  <table
                    className="table table-bordered card-table table-vcenter text-nowrap "
                    id="datatable"
                  >
                    {" "}
                    <thead>
                      <tr>
                        <th>Invoice ID</th>
                        <th scope="row">Customer Name</th>
                        <th>Customer ID</th>
                        <th>Date</th>
                        <th>Order Value</th>
                        <th>Status</th>
                      </tr>{" "}
                    </thead>{" "}
                    <tbody>
                      <tr>
                        <td>
                          {" "}
                          <a className="font-weight-normal1" href="javascript:void(0);">
                            002584611
                          </a>{" "}
                        </td>
                        <td>Joan Powell</td>
                        <td>JoanPowell@gmail.com</td>
                        <td>08/3/2021</td>
                        <td>$450,870</td>
                        <td>
                          {" "}
                          <span className="badge bg-success-transparent border-success fs-11 text-success">
                            Paid
                          </span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <a className="font-weight-normal1" href="javascript:void(0);">
                            004641215
                          </a>{" "}
                        </td>
                        <td>Gavin Gibson</td>
                        <td>JoanPowell@gmail.com</td>
                        <td>08/3/2021</td>
                        <td>$230,540</td>
                        <td>
                          {" "}
                          <span className="badge bg-danger-transparent border-danger fs-11 text-danger">
                            Failed
                          </span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <a className="font-weight-normal1" href="javascript:void(0);">
                            004655445
                          </a>{" "}
                        </td>
                        <td>Julian Kerr</td>
                        <td>JoanPowell@gmail.com</td>
                        <td>08/3/2021</td>
                        <td>$55,300</td>
                        <td>
                          {" "}
                          <span className="badge bg-success-transparent border-success fs-11 text-success">
                            Paid
                          </span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <a className="font-weight-normal1" href="javascript:void(0);">
                            004343455
                          </a>{" "}
                        </td>
                        <td>Cedric Kelly</td>
                        <td>JoanPowell@gmail.com</td>
                        <td>08/3/2021</td>
                        <td>$234,100</td>
                        <td>
                          {" "}
                          <span className="badge bg-success-transparent border-success fs-11 text-success">
                            Paid
                          </span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <a className="font-weight-normal1" href="javascript:void(0);">
                            004651234
                          </a>{" "}
                        </td>
                        <td>Junior Sam</td>
                        <td>JoanPowell@gmail.com</td>
                        <td>08/3/2021</td>
                        <td>$43,198</td>
                        <td>
                          {" "}
                          <span className="badge bg-success-transparent border-success fs-11 text-success">
                            Paid
                          </span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <a className="font-weight-normal1" href="javascript:void(0);">
                            00434567
                          </a>{" "}
                        </td>
                        <td>Gabriel</td>
                        <td>JoanPowell@gmail.com</td>
                        <td>08/3/2021</td>
                        <td>$230,540</td>
                        <td>
                          {" "}
                          <span className="badge bg-danger-transparent border-warning fs-11 text-warning">
                            Pending
                          </span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <a className="font-weight-normal1" href="javascript:void(0);">
                            004345623
                          </a>{" "}
                        </td>
                        <td>Zach efron</td>
                        <td>JoanPowell@gmail.com</td>
                        <td>08/3/2021</td>
                        <td>$55,300</td>
                        <td>
                          {" "}
                          <span className="badge bg-success-transparent border-success fs-11 text-success">
                            Paid
                          </span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <a className="font-weight-normal1" href="javascript:void(0);">
                            004345234
                          </a>{" "}
                        </td>
                        <td>Mona matty</td>
                        <td>JoanPowell@gmail.com</td>
                        <td>08/3/2021</td>
                        <td>$234,100</td>
                        <td>
                          {" "}
                          <span className="badge bg-success-transparent border-success fs-11 text-success">
                            Paid
                          </span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <a className="font-weight-normal1" href="javascript:void(0);">
                            004567455
                          </a>{" "}
                        </td>
                        <td>Samantha May</td>
                        <td>JoanPowell@gmail.com</td>
                        <td>08/3/2021</td>
                        <td>$43,198</td>
                        <td>
                          {" "}
                          <span className="badge bg-danger-transparent border-warning fs-11 text-warning">
                            Pending
                          </span>{" "}
                        </td>
                      </tr>{" "}
                    </tbody>
                  </table>{" "}
                </div>{" "}
              </div>{" "}
              <div className="card-footer border-top-0">
                {" "}
                <div className="d-sm-flex align-items-center">
                  {" "}
                  <div>
                    {" "}
                    Showing 5 Entries <i className="bi bi-arrow-right ms-2 fw-semibold" />{" "}
                  </div>{" "}
                  <div className="ms-auto">
                    {" "}
                    <nav aria-label="Page navigation" className="pagination-style-4">
                      {" "}
                      <ul className="pagination float-end mb-4">
                        {" "}
                        <li className="page-item page-prev disabled">
                          {" "}
                          <a
                            className="page-link"
                            href="javascript:void(0);"
                            tabIndex={-1}
                          >
                            Prev
                          </a>{" "}
                        </li>{" "}
                        <li className="page-item active">
                          <a className="page-link" href="javascript:void(0);">
                            1
                          </a>{" "}
                        </li>{" "}
                        <li className="page-item">
                          <a className="page-link" href="javascript:void(0);">
                            2
                          </a>
                        </li>{" "}
                        <li className="page-item">
                          <a className="page-link" href="javascript:void(0);">
                            3
                          </a>
                        </li>{" "}
                        <li className="page-item page-next">
                          {" "}
                          <a className="page-link" href="javascript:void(0);">
                            Next
                          </a>{" "}
                        </li>{" "}
                      </ul>{" "}
                    </nav>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>


          {/* <div className="row p-5 py-md-5">
        <div className="rounded shadow">
          <div className="d-flex align-items-center justify-content-center">
            <div className="">
              <div className="p-3">
                <h5 className="mb-0">{name?.name || "N/A"}</h5>
              </div>
              <div className="p-3">
                <h6 className="mb-0">{name?.traderId || "N/A"}</h6>
              </div>
              <div className="p-3">
                <h5 className="mb-0">{name?.email || "N/A"}</h5>
              </div>
              <div className="p-3">
                <h6>
                  {/* Balance: {data?.walletBalance ? Number(data?.walletBalance).toFixed(4) : "0"} {data?.asset || ""} */}
          {/* </h6>
              </div>

            </div>
            <div className="vr mt-3"></div>
            <div className="cr">
              <div className="p-3">
              <h6>members</h6>
              <p>{userInfo?.members}</p>
              </div>
             
             
            </div>
          </div>
          <hr />
        </div>
      </div> */}




          <div className="row p-1 p-md-2 uio">

            <div className='col-lg-12'>




            </div>


          </div>

        </div>



      </div>
    </>
  );
};

export default Admin;
