import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { _fetch } from "../config/Api";
import { base_url } from "../config/config";
import toast from "react-hot-toast";

const Viewmemberdetails = () => {
  const [data, setData] = useState();
  const location = useLocation();
  const getQueryParams = (search) => {
    return new URLSearchParams(search);
  };

  const queryParams = getQueryParams(location.search);
  const id = queryParams.get("id");

  const handleMemberInfo = async () => {
    try {
      const requestData = {
        id: id,
      };

      let response = await _fetch(
        `${base_url}api/trader/memberInfo`,
        "post",
        requestData
      );

      if (response.success === true) {
        setData(response);
        console.log(response);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  };

  useEffect(() => {
    handleMemberInfo();
  }, []);

  return (
    <>
      <div className="container memInfoTable">
        <h2 className="text-center mb-3 text-primary">Member Information</h2>
        <div className="row mb-md-5 mb-3">
          <div className="col-md-6 mb-3">
            <div className="card h-100 text-black bg-light border-info">
              <div className="card-body">
                <h4 className="card-title">User Details</h4>
                <hr />
                {data?.user && data?.user !== "" ? (
                  <>
                    <h6>User ID: {data?.user?.userId}</h6>
                    <h6>Sponsor ID: {data?.user?.sponserId}</h6>
                    <h6>Name: {data?.user?.name}</h6>
                    <h6>Email: {data?.user?.email}</h6>
                    <h6>Phone Number: {data?.user?.phonenumber}</h6>
                    <h6>Package Amount: {data?.user?.packageAmount}</h6>
                    <h6>Package Validity: {data?.user?.packageValidity}</h6>
                    <h6>
                      Verified Status:{" "}
                      <span
                        className={
                          data?.user?.isVerified
                            ? "text-success"
                            : "text-danger"
                        }
                      >
                        {data?.user?.isVerified ? "True" : "False"}
                      </span>
                    </h6>
                  </>
                ) : (
                  <p className="text-muted">No user data available</p>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="card h-100 text-black bg-light border-info">
              <div className="card-body">
                <div className="mb-4">
                  <h4 className="card-title">Income Balance</h4>
                  <hr className="bg-light" />
                  {data?.incomeWallet && data?.incomeWallet !== "" ? (
                    <>
                      <h6>Balance: {data?.incomeWallet?.balance}</h6>
                      <h6>Token: {data?.incomeWallet?.tokenName}</h6>
                    </>
                  ) : (
                    <p className="text-muted">
                      No income wallet data available
                    </p>
                  )}
                </div>
                <div>
                  <h4 className="card-title">Fuel Wallet</h4>
                  <hr className="bg-light" />
                  {data?.fuelWallet && data?.fuelWallet !== "" ? (
                    <>
                      <h6>Balance: {data?.fuelWallet?.balance}</h6>
                      <h6>Token: {data?.fuelWallet?.tokenName}</h6>
                    </>
                  ) : (
                    <p className="text-muted">No fuel wallet data available</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-md-5 mb-3">
          <h4 className="text-black">Trades</h4>
          <div className="table-responsive" style={{ height: "300px" }}>
            <table className="table table-bordered border-info bg-light text-black">
              <thead className="thead-light">
                <tr>
                  <th>S No.</th>
                  {/* <th>Trade ID</th> */}
                  <th>Symbol</th>
                  <th>Side</th>
                  <th>Type</th>
                  <th>Start Price</th>
                  <th>End Price</th>
                  <th>Profit/Loss</th>
                  <th>Status</th>
                  <th>Close Status</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {data?.userTrades?.length > 0 ? (
                  data.userTrades.map((trade, index) => (
                    <tr key={trade._id}>
                      <td>{index + 1}</td>
                      {/* <td>{trade.tradeId}</td> */}
                      <td>{trade.symbol}</td>
                      <td>{trade.side}</td>
                      <td>{trade.type}</td>
                      <td>{trade.startPrice}</td>
                      <td>{trade.endPrice}</td>
                      <td
                        className={
                          trade.profitLoss > 0
                            ? "text-success"
                            : trade.profitLoss < 0
                            ? "text-danger"
                            : ""
                        }
                      >
                        {trade.profitLoss}
                      </td>
                      <td>{trade.status}</td>
                      <td>{trade.closeStatus ? "Closed" : "Open"}</td>
                      <td>{new Date(trade.createdAt).toLocaleString()}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="10" className="text-center">
                      No trades available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row mb-md-5 mb-3">
          <div className="col-md-6 mb-3">
            <h4 className="text-black">Fund Transaction</h4>
            <div className="table-responsive" style={{ height: "300px" }}>
              <table className="table table-bordered border-info bg-light text-black">
                <thead className="thead-light">
                  <tr>
                    <th>S No.</th>
                    {/* <th>Transaction ID</th> */}
                    <th>From</th>
                    <th>To</th>
                    <th>Amount</th>
                    <th>Token Name</th>
                    <th>Type</th>
                    <th>Wallet Type</th>
                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.fundTxn?.length > 0 ? (
                    data.fundTxn.map((txn, index) => (
                      <tr key={txn._id}>
                        <td>{index + 1}</td>
                        {/* <td>{txn._id}</td> */}
                        <td>{txn.from}</td>
                        <td>{txn.to}</td>
                        <td>{txn.amount}</td>
                        <td>{txn.tokenName}</td>
                        <td>{txn.transactionType}</td>
                        <td>{txn.walletType}</td>
                        <td>{new Date(txn.createdAt).toLocaleString()}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center">
                        No transactions available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <h4 className="text-black">Trade Fee Transaction</h4>
            <div className="table-responsive" style={{ height: "300px" }}>
              <table className="table table-bordered border-info bg-light text-black">
                <thead className="thead-light">
                  <tr>
                    <th>S No.</th>
                    {/* <th>Transaction ID</th> */}
                    <th>From</th>
                    <th>To</th>
                    <th>Amount</th>
                    <th>Token Name</th>
                    <th>Mode</th>
                    <th>Type</th>
                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.tradeFeeTxn?.length > 0 ? (
                    data.tradeFeeTxn.map((txn, index) => (
                      <tr key={txn._id}>
                        <td>{index + 1}</td>
                        {/* <td>{txn._id}</td> */}
                        <td>{txn.from}</td>
                        <td>{txn.to}</td>
                        <td
                          className={
                            txn.amount > 0
                              ? "text-success"
                              : txn.amount < 0
                              ? "text-danger"
                              : ""
                          }
                        >
                          {txn.amount.toFixed(2)}
                        </td>
                        <td>{txn.tokenName}</td>
                        <td>{txn.mode}</td>
                        <td>{txn.transactionType}</td>
                        <td>{new Date(txn.createdAt).toLocaleString()}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center">
                        No trade fee transactions available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row mb-md-5 mb-3">
          <div className="col-md-6 mb-3">
            <h4 className="text-black">Direct Bonus Transaction</h4>
            <div className="table-responsive" style={{ height: "300px" }}>
              <table className="table table-bordered border-info bg-light text-black">
                <thead className="thead-light">
                  <tr>
                    <th>S No.</th>
                    {/* <th>Transaction ID</th> */}
                    <th>From</th>
                    <th>To</th>
                    <th>Amount</th>
                    <th>Token Name</th>
                    <th>Type</th>
                    <th>Wallet Type</th>
                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.directBonus?.length > 0 ? (
                    data.directBonus.map((txn, index) => (
                      <tr key={txn._id}>
                        <td>{index + 1}</td>
                        {/* <td>{txn._id}</td> */}
                        <td>{txn.from}</td>
                        <td>{txn.to}</td>
                        <td>{txn.amount}</td>
                        <td>{txn.tokenName}</td>
                        <td>{txn.transactionType}</td>
                        <td>{txn.walletType}</td>
                        <td>{new Date(txn.createdAt).toLocaleString()}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center">
                        No direct bonus transactions available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="col-md-6 mb-3">
            <h4 className="text-black">Account Activation Transaction</h4>
            <div className="table-responsive" style={{ height: "300px" }}>
              <table className="table table-bordered border-info bg-light text-black">
                <thead className="thead-light">
                  <tr>
                    <th>S No.</th>
                    {/* <th>Transaction ID</th> */}
                    {/* <th>User ID</th> */}
                    <th>Token Name</th>
                    <th>Value</th>
                    <th>Type</th>
                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.accountActivationTxn?.length > 0 ? (
                    data.accountActivationTxn.map((txn, index) => (
                      <tr key={txn._id}>
                        <td>{index + 1}</td>
                        {/* <td>{txn._id}</td> */}
                        {/* <td>{txn.userId}</td> */}
                        <td>{txn.tokenName}</td>
                        <td>{txn.value}</td>
                        <td>{txn.transactionType}</td>
                        <td>{new Date(txn.createdAt).toLocaleString()}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center">
                        No account activation transactions available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row mb-md-5 mb-3">
          <h4 className="text-black">Deposit Transactions</h4>
          <div className="table-responsive" style={{ height: "300px" }}>
            <table className="table table-bordered border-info bg-light text-black">
              <thead className="thead-light">
                <tr>
                  <th>S No.</th>
                  {/* <th>Transaction ID</th> */}
                  {/* <th>User ID</th> */}
                  {/* <th>Trader ID</th> */}
                  <th>Hash</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Amount</th>
                  {/* <th>Wallet Type</th> */}
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {data?.depositTxn?.length > 0 ? (
                  data.depositTxn.map((deposit, index) => (
                    <tr key={deposit._id}>
                      <td>{index + 1}</td>
                      {/* <td>{deposit._id}</td> */}
                      {/* <td>{deposit.userId}</td> */}
                      {/* <td>{deposit.traderId}</td> */}
                      <td>{deposit.hash}</td>
                      <td>{deposit.from}</td>
                      <td>{deposit.to}</td>
                      <td
                        className={
                          deposit.amount > 0
                            ? "text-success"
                            : deposit.amount < 0
                            ? "text-danger"
                            : ""
                        }
                      >
                        {deposit.amount.toFixed(2)}
                      </td>
                      {/* <td>{deposit.walletType}</td> */}
                      <td>{new Date(deposit.createdAt).toLocaleString()}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center">
                      No Deposits available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row mb-md-5 mb-3">
          <h4 className="text-black">User Withdrawals</h4>
          <div className="table-responsive" style={{ height: "300px" }}>
            <table className="table table-bordered border-info bg-light text-black">
              <thead className="thead-light">
                <tr>
                  <th>S No.</th>
                  {/* <th>Transaction ID</th> */}
                  {/* <th>User ID</th> */}
                  {/* <th>Trader ID</th> */}
                  <th>Token Name</th>
                  <th>Amount</th>
                  {/* <th>Wallet Type</th> */}
                  <th>Status</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {data?.userWithraw?.length > 0 ? (
                  data.userWithraw.map((withdraw, index) => (
                    <tr key={withdraw._id}>
                      <td>{index + 1}</td>
                      {/* <td>{withdraw._id}</td> */}
                      {/* <td>{withdraw.userId}</td> */}
                      {/* <td>{withdraw.traderId}</td> */}
                      <td>{withdraw.tokenName}</td>
                      <td
                        className={
                          withdraw.amount > 0
                            ? "text-success"
                            : withdraw.amount < 0
                            ? "text-danger"
                            : ""
                        }
                      >
                        {withdraw.amount.toFixed(2)}
                      </td>
                      {/* <td>{withdraw.walletType}</td> */}
                      <td>{withdraw.status}</td>
                      <td>{new Date(withdraw.createdAt).toLocaleString()}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center">
                      No withdrawals available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Viewmemberdetails;
