// export const base_url = `https://forexbotapi.metagainfx.com/`

// export const base_url = `http://139.59.85.17:3200/`
//
// export const base_url = `http://192.168.1.12:8100/`

// export const base_url = `https://209.97.173.161:8100/`

// export const base_url = `https://api.aibittrade.com/`
// export const base_url = `http://192.168.1.72:8100/`;
export const base_url = `https://crypto-trading.jstechservices.us/`
// https://crypto-trading.jstechservices.us/api-docs/