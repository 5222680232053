// import React from 'react'

// const Bonus = () => {
//   return (
//     <div>

//     </div>
//   )
// }

// export default Bonus

import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { _fetch } from "../config/Api";
import { base_url } from "../config/config";
import { ColorRing } from "react-loader-spinner";
import dateFormat from "dateformat";
import { Pagination } from "antd";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";

const Bonus = () => {
  const [userId, setUserId] = useState("");
  const [data, setData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSer, setpageSer] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [email, setEmail] = useState("");
  const [sponserId, SetSponserId] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [currency, setCurrency] = useState("");
  const [name, setName] = useState("");
  const [leverage, setLeverage] = useState("");
  const [transactionType, setTransactionType] = useState("");
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
    userId: "",
    email: "",
    phonenumber: "",
    sponserId: "",
    name: "",
    currency: "",
    leverage: "",
  });
  const applyFilters = () => {
    setFilters({
      startDate,
      endDate,
      userId,
      email,
      currency,
      leverage,
      phonenumber,
      sponserId,
      transactionType,
      name,
    });
  };
  const resetFilters = () => {
    setStartDate("");
    setEndDate("");
    setUserId("");
    setName("");
    setEmail("");
    setPhonenumber("");
    SetSponserId("");
    setCurrency("");
    setTransactionType("");
    setLeverage("");
    setFilters({
      startDate: "",
      name: "",
      endDate: "",
      userId: "",
      email: "",
      phonenumber: "",
      sponserId: "",
      currency: "",
      leverage: "",
      transactionType: "",
    });
    setCurrentPage(1);
    handledashboard(1, 10, {}); // Fetch all data again
  };

  const handledashboard = async (page = 1, pageSize = 10) => {
    try {
      const skip = (page - 1) * pageSize;
      setpageSer(skip);
      const requestData = {
        limit: pageSize,
        skip: skip,
        name: filters.name,
        uid: filters.userId,
        from: filters.startDate,
        to: filters.endDate,
        transactionType: "directBonus",
      };

      let response = await _fetch(
        `${base_url}api/trader/memberTxn`,
        "post",
        requestData
      );

      if (response.success === true) {
        setData(response);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  };

  useEffect(() => {
    handledashboard(currentPage, 10);
  }, [currentPage, filters]);
  const handlePaginationChange = (pageOne) => {
    setCurrentPage(pageOne);
  };

  const records = () => {
    if (data && data?.data && data?.data?.length > 0) {
      return data?.data?.map((row, i) => {
        const serialNumber = pageSer + i + 1;
        return (
          <tr className="table-row" key={i}>
            <td className="tabel-text w inter">{serialNumber}</td>
            <td className="tabel-text w inter">{row?.userId?.userId}</td>
            <td className="tabel-text w inter">{row?.userId?.name}</td>

            <td className="tabel-text w inter text-success">
              {row?.tokenName}
            </td>
            <td className="tabel-text w inter">{row?.from}</td>
            <td className="tabel-text w inter">{row?.to}</td>
            <td className="tabel-text w inter">{row?.value}</td>
            <td className="tabel-text w inter">{row?.walletType}</td>
            <td className="tabel-text w inter">{row?.transactionType}</td>
            <td className="tabel-text w inter">
              {dateFormat(row.createdAt, "yyyy-mm-dd, HH:mm:ss")}
            </td>

            {/* <td>{dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</td> */}
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="13">Data Not Found</td>
        </tr>
      );
    }
  };
  // const records = () => {
  //   if (data && data?.data && data?.data?.length > 0) {
  //     return data?.data?.map((row, i) => {
  //       const serialNumber = pageSer + i + 1;
  //       return (
  //         <div key={i} className="container mt-5 p-1">
  //           <div className="card border-primary">
  //             <div className="card-header bg-white d-flex justify-content-between align-items-center">
  //               <span className="badge bg-success">{serialNumber}</span> {row?.symbol}
  //             </div>
  //             <div className="card-body p-2">
  //               <div className="row p-0">
  //                 <div className="col-md-4">
  //                   <dl className="row mb-0 positionRow">
  //                     <div className='sec-main'>
  //                       <dt className="col-6">Name:</dt>
  //                       <dd className="col-6">{row?.userId?.name}</dd>
  //                     </div>
  //                     <div className='sec-main'>
  //                       <dt className="col-6">User Id:</dt>
  //                       <dd className="col-6">{row?.userId?.userId}</dd>
  //                     </div>
  //                     <div className='sec-main'>
  //                       <dt className="col-6">TokenName:</dt>
  //                       <dd className="col-6">{row?.tokenName}</dd>
  //                     </div>

  //                   </dl>
  //                 </div>
  //                 <div className="col-md-4">
  //                   <dl className="row mb-0 positionRow">
  //                   <div className='sec-main'>
  //                       <dt className="col-6">From:</dt>
  //                       <dd className="col-6">{row?.from}</dd>
  //                     </div>
  //                     <div className='sec-main'>
  //                       <dt className="col-6">To:</dt>
  //                       <dd className="col-6">{row?.to}</dd>
  //                     </div>
  //                     <div className='sec-main'>
  //                       <dt className="col-6">value:</dt>
  //                       <dd className="col-6">{row?.value}</dd>
  //                     </div>

  //                   </dl>
  //                 </div>
  //                 <div className="col-md-4">
  //                   <dl className="row mb-0 positionRow">

  //                     <div className='sec-main'>
  //                       <dt className="col-6">Wallet Type</dt>
  //                       <dd className="col-6">{row?.walletType}</dd>
  //                     </div>
  //                     <div className='sec-main'>
  //                       <dt className="col-6">Transaction Type</dt>
  //                       <dd className="col-6">{row?.transactionType}</dd>
  //                     </div>

  //                     <div className='sec-main'>
  //                       <dt className="col-6">Date:</dt>
  //                       <dd className="col-6">{dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</dd>
  //                     </div>

  //                   </dl>
  //                 </div>

  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       );
  //     });
  //   } else {
  //     return <div>Data Not Found</div>;
  //   }
  // };

  const getExportData = async () => {
    try {
      const exportRequest = {
        limit: 1000,
        skip: 0,
        from: filters.startDate,
        to: filters.endDate,
        transactionType: "directBonus",
        export: true,
      };

      const response = await _fetch(
        `${base_url}api/trader/memberTxn`,
        "post",
        exportRequest
      );

      if (!response.success) {
        throw new Error(response.message || "Failed to fetch export data");
      }

      return response.data;
    } catch (error) {
      console.error("Export data fetch error:", error);
      toast.dismiss();
      toast.error("Failed to get export data");
      throw error;
    }
  };

  const exportToPDF = async () => {
    try {
      const data = await getExportData();

      const doc = new jsPDF();

      const tableColumns = [
        "S No.",
        "UserId",
        "Name",
        "Token Name",
        "From",
        "To",
        "value",
        "Wallet Type",
        "Transaction Type",
        "Date",
      ];

      const tableRows = data.map((item, index) => [
        index + 1,
        item.userId?.userId,
        item.userId?.name,
        item.tokenName,
        item.from,
        item.to,
        item.value,
        item.walletType,
        item.transactionType,
        dateFormat(item.createdAt, "yyyy-mm-dd"),
      ]);

      doc.text("Bonus transaction Report", 14, 15);

      doc.autoTable({
        head: [tableColumns],
        body: tableRows,
        startY: 20,
        theme: "grid",
        styles: { fontSize: 8 },
      });

      doc.save("bonus-transaction-resport.pdf");
      toast.dismiss();
      toast.success("PDF downloaded successfully");
    } catch (error) {
      console.error("PDF export error:", error);
      toast.dismiss();
      toast.error("Failed to export PDF");
    }
  };

  const exportToExcel = async () => {
    try {
      const data = await getExportData();
      const excelData = data.map((item, index) => ({
        "S No.": index + 1,
        UserId: item.userId?.userId,
        Name: item.userId?.name,
        "Token Name": item.tokenName,
        From: item.from,
        To: item.to,
        value: item.value,
        "Wallet Type": item.walletType,
        "Transaction Type": item.transactionType,
        Date: dateFormat(item.createdAt, "yyyy-mm-dd"),
      }));

      const worksheet = XLSX.utils.json_to_sheet(excelData);

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        "Bonus transaction History"
      );

      XLSX.writeFile(workbook, "bonus-transaction-history.xlsx");
      toast.dismiss();
      toast.success("Excel file downloaded successfully");
    } catch (error) {
      console.error("Excel export error:", error);
      toast.dismiss();
      toast.error("Failed to export Excel");
    }
  };

  return (
    <>
      <div className="container container-trade p-0">
        <div className="">
          <h1 className="text-primary text-center">Bonus Transactions</h1>
          <div className="container container-trade2 mt-2 p-1">
            <div className="text-right1 p-3">
              <button className="btn btn-primary me-2" onClick={exportToPDF}>
                Export PDF
              </button>
              <button className="btn btn-primary" onClick={exportToExcel}>
                Export Excel
              </button>
            </div>
            <div className="card back-card">
              <div className="main-12">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-6 col-6 p-1 m-auto">
                    <input
                      type="date"
                      className="form-control"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-6 p-1 m-auto">
                    <input
                      type="date"
                      className="form-control"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                  {/* <div className="col-lg-4 col-md-4 col-sm-6 col-6 p-1 m-auto">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      onChange={(e) => setTransactionType(e.target.value)}
                      value={transactionType}
                    >
                      <option value={""}>Select Transaction Type </option>
                      <option value={"traderFundTransfer"}>Fund transfer from trade</option>
                      <option value={"tradeFee"}>Trade fees</option>
                      <option value={"directBonus"}>Bonus from direct users to sponser</option>
                      <option value={"deposit"}>Deposit transaction</option>
                      <option value={"userFundTransfer"}>User to user fund transfer</option>
                      <option value={"accountActivation"}>Account activation</option>
                    </select>
                  </div> */}
                  {/* <div className='col-lg-4 col-md-4 col-sm-6 col-6 p-1'>
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected>UserId</option>
                                                <option value="1">Email</option>
                                                <option value="2">Phone</option>
                                                <option value="3">sponserId</option>
                                            </select>
                                        </div> */}
                  {/* <div className="col-lg-4 col-md-4 col-sm-6 col-6 p-1 m-auto">
                  <div className="search-btn">
                    <input
                      type="email"
                      placeholder="email Search"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div> */}

                  <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1 m-auto">
                    <div className="search-btn">
                      <input
                        type="text"
                        placeholder="User Id Search"
                        value={userId}
                        onChange={(e) => setUserId(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1 m-auto">
                    <div className="search-btn">
                      <input
                        type="text"
                        placeholder="Name Search"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                  {/* <div className="col-lg-4 col-md-4 col-sm-6 col-6 p-1 m-auto">
                  <div className="search-btn">
                    <input
                      type="number"
                      placeholder="phone number Search"
                      value={phonenumber}
                      onChange={(e) => setPhonenumber(e.target.value)}
                    />
                  </div>
                </div> */}

                  {/* <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1 m-auto">
                  <div className="search-btn">
                    <input
                      type="text"
                      placeholder="sponser Id Search"
                      value={sponserId}
                      onChange={(e) => SetSponserId(e.target.value)}
                    />
                  </div>
                </div> */}
                  {/* <div className="col-lg-4 col-md-4 col-sm-6 col-6 p-1 m-auto">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      onChange={(e) => setCurrency(e.target.value)}
                      value={currency}
                    >
                      {console.log(currencyData)}
                      <option value={""}>Select Currency</option>
                      {currencyData && currencyData.length > 0
                        ? currencyData.map((currency, index) => (
                            <option key={index} value={currency.token}>
                              {currency.token}
                            </option>
                          ))
                        : ""}
                    </select>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1 m-auto">
                    <div className="search-btn">
                      <input
                        type="number"
                        placeholder="Leverage"
                        value={leverage}
                        onChange={(e) => setLeverage(e.target.value)}
                      />
                    </div>
                  </div> */}
                  <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1">
                    <div className="reset-btn">
                      <button className="apply-btn" onClick={applyFilters}>
                        Apply Filters
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1">
                    <div className="place-2">
                      <button
                        onClick={resetFilters}
                        className="btn btn-secondary"
                      >
                        Reset Filters
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h4 className="text-right1 p-3 text-primary">
              Bonus Transactions: {data?.count}
            </h4>
          </div>
          <div className="container container-trade2 mt-2 p-1">
            <div className="transactions">
              {/* {records()} */}
              <div className="card border-primary">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">UserId</th>
                      <th scope="col">Name</th>
                      <th scope="col">Token Name</th>
                      <th scope="col">From</th>
                      <th scope="col">To </th>
                      <th scope="col">value</th>
                      <th scope="col">Wallet Type</th>
                      <th scope="col">Transaction Type</th>
                      <th scope="col">Date </th>
                    </tr>
                  </thead>
                  <tbody>{records()}</tbody>
                </table>
              </div>
            </div>
            <div className="pagination mt-3">
              <Pagination
                style={{ cursor: "pointer" }}
                className="mx-auto"
                current={currentPage || 1}
                total={data?.count || 0}
                pageSize={10}
                onChange={handlePaginationChange}
                showSizeChanger={false}
                showQuickJumper={false}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Bonus;
